<template>
  <app-card-wizard title="Firma Bilgileri">
    <template #description>
      <p>
        Bu adımda kaydı yapılacak firma ile ilgilili bilgileri doldurmanız
        gerekmektedir. Belirtilen bilgiler firma kanuni merkezine ait bilgiler
        olmalıdır.
      </p>
    </template>
    <app-value-outlet
      label="Vergi Numarası"
      :editable="false"
      :value="taxNumber"
      @edit="onTaxNumberChange"
    ></app-value-outlet>

    <app-form-group
      :validator="validator"
      name="companyName"
      label="Firma Unvanı"
      help="Resmi gazetede belirtildiği şekilde başvurusu yapılacak olan firmanın ticari unvanı"
    >
      <input
        name="companyName"
        class="form-control"
        placeholder="Firmanın açık unvanını girin"
        maxlength="100"
        v-model.trim="companyName"
        @change="updateCompanyInfo"
      />
    </app-form-group>
    <app-form-group
      v-if="isBrokerRegistration"
      :validator="validator"
      name="hqCity"
      label="Kanuni Merkez Şehri"
      help="Firmanın kanuni merkezinin bulunduğu şehiri seçin"
    >
      <p-dropdown
        id="hqCity"
        style="width:100%;"
        v-model="hqCity"
        :options="cities"
        :showClear="true"
        :filter="true"
        filterPlaceholder="Arama yapın"
        filterLocale="tr"
        emptyFilterMessage="Sonuç bulunamadı"
        dataKey="slug"
        scrollHeight="270px"
        optionLabel="name"
        optionValue="slug"
        placeholder="Firmanın kanuni merkezinin bulunduğu şehiri seçin"
        @change="onHqCityChange"
      />
    </app-form-group>
    <app-form-group
      :validator="validator"
      name="taxOffice"
      label="Vergi Dairesi"
      help="Başvurusu yapılacak olan firmanın kayıtlı olduğu vergi daires"
    >
      <p-dropdown
        style="width:100%;"
        v-model="taxOffice"
        :options="taxOffices"
        :showClear="true"
        :filter="true"
        :dataKey="id"
        :optionLabel="getTaxOfficeName"
        :disabled="!hasHqCity"
        filterLocale="tr"
        filterPlaceholder="Ara"
        emptyFilterMessage="Sonuç bulunamadı"
        :placeholder="
          hasHqCity
            ? 'Firmanın kayıtlı olduğu vergi daires'
            : 'Önce kanuni merkez şehrini seçin'
        "
        @change="updateCompanyInfo($event, 'taxOffice', $event.value)"
      >
        <template v-once #option="{ option }">
          <span>{{ getTaxOfficeName(option, true) }}</span>
          <div class="p-dropdown-desc small">
            <span>{{ option.city + " / " + option.county }}</span>
          </div>
        </template>
      </p-dropdown>
    </app-form-group>
    <app-form-group
      :validator="validator"
      name="mersisNumber"
      label="Mersis No"
      help="Başvurusu yapılacak olan firmanın mersis numarası"
    >
      <input
        name="mersisNumber"
        v-model.trim.lazy="mersisNumber"
        v-numeric
        maxlength="16"
        class="form-control"
        placeholder="Firma mersis numarası"
        @input="updateCompanyInfo"
      />
    </app-form-group>

    <app-form-group
      :validator="validator"
      v-if="isSoleCompany"
      name="tradeRegistrationType"
      label="Firmanın Kayıtlı Olduğunuz Kurum"
      help="Başvurusu yapılacak olan firmanın kayıtlı olduğu kurum türünü seçin"
    >
      <p-dropdown
        style="width:100%;"
        v-model="tradeRegistrationType"
        :options="tradeRegistrationTypes"
        optionLabel="label"
        optionValue="name"
        :showClear="true"
        dataKey="name"
        placeholder="Firmanız hangi odaya kayıtlı?"
        @change="onTradeRegistrationTypeChange"
      >
      </p-dropdown>
    </app-form-group>
    <template v-if="tradeRegistrationType === 'esnaf-ve-sanatkarlar-odasi'">
      <app-form-group
        :validator="validator"
        name="tradeRegistrationOffice"
        label="Esnaf ve Sanatkarlar Odası Adı"
        help="Başvurusu yapılacak olan firmanın kayıtlı olduğu esnaf ve saatkarlar odası adı"
      >
        <input
          name="tradeRegistrationOffice"
          v-model.trim.lazy="tradeRegistrationOffice"
          class="form-control"
          placeholder="Esnaf ve sanatkar odası adı"
          @input="
            updateCompanyInfo($event, 'tradeRegistrationOffice', $event.value)
          "
        />
      </app-form-group>
      <app-form-group
        :validator="validator"
        name="tradeRegistrationNumber"
        label="Esnaf ve Sanatkarlar Odası Sicil No"
        help="Başvurusu yapılacak olan firmanın oda sicil numarası"
      >
        <input
          name="tradeRegistrationNumber"
          v-model.trim="tradeRegistrationNumber"
          maxlength="13"
          class="form-control"
          placeholder="Esnaf odası sicil numarasını girin"
          @input="onTradeRegistrationNumberChange"
          @keypress="onTradeRegistrationKeypress"
        />
      </app-form-group>
    </template>
    <template v-if="tradeRegistrationType !== 'esnaf-ve-sanatkarlar-odasi'">
      <app-form-group
        :validator="validator"
        name="tradeRegistrationOffice"
        label="Ticari Sicil Müdürlüğü"
        help="Başvurusu yapılacak olan firmanın kayıtlı olduğu ticari sicil müdürlüğü"
      >
        <p-dropdown
          style="width:100%;"
          v-model="tradeRegistrationOffice"
          :options="tradeRegistrationOffices"
          :showClear="true"
          :filter="true"
          :disabled="!hasHqCity"
          filterLocale="tr"
          filterPlaceholder="Ara"
          emptyFilterMessage="Sonuç bulunamadı"
          :placeholder="
            hasHqCity
              ? 'Firmanın kayıtlı olduğu ticari sicil müdürlüğünü seçin'
              : 'Önce kanuni merkez şehrini seçin'
          "
          @change="
            updateCompanyInfo($event, 'tradeRegistrationOffice', $event.value)
          "
        >
        </p-dropdown>
      </app-form-group>
      <app-form-group
        :validator="validator"
        name="tradeRegistrationNumber"
        label="Ticaret Sicil No"
        help="Başvurusu yapılacak olan firmanın ticari sicil müdürlüğüne kayıtlı sicil numarası"
      >
        <input
          name="tradeRegistrationNumber"
          v-model.trim="tradeRegistrationNumber"
          maxlength="13"
          class="form-control"
          placeholder="Firmanın ticari sicil numarasını girin"
          @input="onTradeRegistrationNumberChange"
          @keypress="onTradeRegistrationKeypress"
        />
      </app-form-group>
    </template>

    <app-form-group
      :validator="validator"
      name="naceCodes"
      label="NACE Kodu Seçin"
      help="Firmanızın faaliyet alanlarını belirten NACE kodlarını seçin"
    >
      <app-multi-select
        id="naceCodes"
        class="nace-codes"
        v-model="naceCodes"
        :options="naceCodesList"
        display="chip"
        :filter="true"
        filterPlaceholder="Arama yapın"
        filterLocale="tr"
        :emptyFilterMessage="naceCodesEmptyFilterMessage"
        dataKey="key"
        optionLabel="key"
        optionValue="key"
        placeholder="Faaliyet alanlarını belirten NACE kodlarını seçin"
        style="width:100%"
        @change="onNaceCodesChange"
        @show="onNaceCodesShow"
        @filter="onNaceCodesFilter"
        ref="naceCodes"
      >
        <template #option="slotProps">
          <div>
            <span>{{ slotProps.option.key }}</span>
            <div class="p-dropdown-desc small">
              <span>{{ slotProps.option.value }}</span>
            </div>
          </div>
        </template>
      </app-multi-select>
    </app-form-group>
    <app-form-group
      :validator="validator"
      name="companyCategory"
      label="Firmanızın Konumu"
    >
      <p-multi-select
        id="branchOffices"
        v-model="companyCategory"
        :options="companyCategories"
        display="chip"
        dataKey="name"
        scrollHeight="300px"
        optionLabel="name"
        optionValue="name"
        placeholder="Uygun olan tüm seçenekleri seçin"
        style="width:100%"
        @change="updateCompanyInfo($event, 'companyCategory', $event.value)"
      ></p-multi-select>
    </app-form-group>
    <app-form-group
      v-if="isServiceExporter"
      :validator="validator"
      name="grossSales"
      :label="grossSalesLabel"
      help="Bir önceki yıla ait brüt satış tutatınıs TL cinsinden belirtilmelidir"
    >
      <p-input-number
        name="grossSales"
        v-model.lazy="grossSales"
        :maxFractionDigits="0"
        :min="0"
        suffix=" TL"
        locale="tr-TR"
        style="width:100%"
        placeholder="Firmanın bir önceki yıla ait brüt satış tutarıni girin"
        @input="updateGrossSales"
      />
    </app-form-group>
    <app-wizard-buttons
      :next-button-disabled="isLoading"
      :next-button-is-loading="isLoading"
      :next-button-text="nextButtonText"
      @next-button-click="onSubmit"
      :prev-button-visible="prevButtonVisible"
      :prev-button-disabled="isLoading"
      @prev-button-click="redirect('/applicant/' + applicationData.stateId)"
    ></app-wizard-buttons>
  </app-card-wizard>
</template>

<script>
import {
  companyCategories,
  rules,
  tradeRegistrationOffices,
  taxOffices,
  cities,
  slugify
} from "../shared";
import apiClient from "../services";
//import naceCodesList from "../shared/nace-codes";
import PMultiSelect from "primevue/multiselect";
import AppMultiSelect from "../components/AppMultiSelect.vue";
import PInputNumber from "primevue/inputnumber";
import AppValueOutlet from "../components/ValueOutlet.vue";

const validTradeRegistrationCodeChars = new Set([
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "-"
]);

export default {
  components: {
    AppMultiSelect,
    PMultiSelect,
    PInputNumber,
    AppValueOutlet
  },
  data() {
    return {
      requiredCategories: {
        broker: "Gümrük Müşaviri",
        exporter: "İhracatçı"
      },
      isLoading: false,
      companyName: null,
      taxOffice: null,
      hqCity: null,
      cities,
      mersisNumber: null,
      tradeRegistrationOffice: null,
      tradeRegistrationNumber: null,
      tradeRegistrationType: null,
      tradeRegistrationTypes: [
        {
          label: "Esnaf ve Sanatkarlar Odası",
          name: "esnaf-ve-sanatkarlar-odasi"
        },
        { label: "Ticaret/Sanayi Odası", name: "ticaret-sanayi-odasi" }
      ],
      companyCategory: [],
      grossSales: null,
      naceCodes: [],
      naceCodesList: [],
      naceCodesEmptyFilterMessage: "Sonuç bulunamadı",
      naceCodesFilterTimer: null
    };
  },
  validations: {
    companyName: rules.required,
    taxOffice: rules.required,
    hqCity: rules.required,
    naceCodes: rules.required,
    mersisNumber: rules.mersisNumber,
    tradeRegistrationOffice: rules.required,
    tradeRegistrationNumber: rules.required,
    companyCategory: rules.required,
    grossSales: rules.required
  },
  methods: {
    formatTradeRegistrationNumber(val) {
      return (val + "")
        .split("")
        .filter(x => validTradeRegistrationCodeChars.has(x))
        .join("");

      // if (val && val.length > 6) {
      //   var vLeft = val.substring(0, 6);
      //   var vRight = val.substring(6, val.length);

      //   if (!vRight.startsWith("-")) vRight = "-" + vRight;

      //   if (vRight.length > 7) vRight = vRight.substring(0, 7);

      //   return vLeft + vRight;
      // } else {
      //   return val;
      // }
    },
    onTradeRegistrationTypeChange() {
      this.tradeRegistrationOffice = null;
      this.tradeRegistrationNumber = null;

      this.updateCompanyInfo(
        null,
        "tradeRegistrationOffice",
        this.tradeRegistrationOffice
      );

      this.updateCompanyInfo(
        null,
        "tradeRegistrationNumber",
        this.tradeRegistrationNumber
      );

      this.updateCompanyInfo(
        null,
        "tradeRegistrationType",
        this.tradeRegistrationType
      );
    },
    onTradeRegistrationKeypress(e) {
      if (!validTradeRegistrationCodeChars.has(e.key)) {
        e.preventDefault();
      }
    },
    onTradeRegistrationNumberChange() {
      this.tradeRegistrationNumber = this.formatTradeRegistrationNumber(
        this.tradeRegistrationNumber
      );

      this.updateCompanyInfo(
        null,
        "tradeRegistrationNumber",
        this.tradeRegistrationNumber
      );
    },
    onTaxNumberChange() {
      this.goTo("/check-membership/" + this.applicationData.stateId);
    },
    showDuplicateCompanyAlert(gsName) {
      this.$swal({
        icon: "warning",
        title: "Aynı Sicil Numarası İle Başka Bir Kayıt Var",
        html:
          "<b>" +
          this.tradeRegistrationNumber +
          "</b> ticaret sicil numarası ile" +
          (gsName ? " <b>" + gsName + "ne</b> " : " ") +
          "kayıtlı başka bir üyelik daha var. Lütfen bilgilerinizi kontrol ediniz.",
        confirmButtonText: "Tamam",
        showCancelButton: false
      });
    },
    showCategoryAlert(categoryName) {
      this.$swal({
        icon: "warning",
        title: categoryName + " kategorisi seçilmelidir",
        html:
          "İşleme devam edebilmek için firma konumu olarak <b>" +
          categoryName +
          "</b> seçeneğini de seçmiş olmanız gerekmektedir.",
        confirmButtonText: "Tamam",
        showCancelButton: false
      });
    },
    onSubmit() {
      if (!this.isServiceExporter) this.grossSales = 0;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.scrollToError();
        return;
      }

      let isCategoryValid = true;

      if (this.isBrokerRegistration) {
        if (!this.companyCategory.includes(this.requiredCategories.broker)) {
          this.showCategoryAlert(this.requiredCategories.broker);
          isCategoryValid = false;
        }
      } else {
        if (!this.companyCategory.includes(this.requiredCategories.exporter)) {
          this.showCategoryAlert(this.requiredCategories.exporter);
          isCategoryValid = false;
        }
      }

      if (!isCategoryValid) {
        return;
      }

      this.isLoading = true;

      let isDuplicate = false;
      let duplicateRecordGsName;

      apiClient
        .checkTradeRegistration(
          this.tradeRegistrationOffice,
          this.tradeRegistrationNumber
        )
        .then(response => {
          if (response && response.data) {
            if (this.companyType.value === "shsfrm") {
              isDuplicate = response.data.taxNumber != this.identityNumber;
            } else {
              isDuplicate = response.data.taxNumber != this.taxNumber;
            }

            if (isDuplicate && response.data.gsName) {
              duplicateRecordGsName = response.data.gsName;
            }
          }
        })
        .then(() => {
          if (isDuplicate) {
            this.isLoading = false;
            this.showDuplicateCompanyAlert(duplicateRecordGsName);
          } else {
            setTimeout(() => {
              const companyInfo = this.applicationData.companyInfo || {};

              companyInfo.taxNumber = this.taxNumber;
              companyInfo.identityNumber = this.identityNumber;
              companyInfo.companyName = this.companyName;
              companyInfo.taxOffice = this.taxOffice;
              companyInfo.mersisNumber = this.mersisNumber;
              companyInfo.tradeRegistrationOffice = this.tradeRegistrationOffice;
              companyInfo.tradeRegistrationNumber = this.tradeRegistrationNumber;
              companyInfo.tradeRegistrationType = this.tradeRegistrationType;
              companyInfo.companyType = this.companyType;
              companyInfo.companyCategory = this.companyCategory;
              companyInfo.grossSales = this.grossSales;
              companyInfo.naceCodes = this.naceCodes;

              this.updateApplicationData({ companyInfo });

              this.isLoading = false;
              this.markStepAsCompleted(this.stepName);

              const path = this.applicationData.fromReview
                ? "/review/"
                : "/company-address-hq/";

              this.goTo(path + this.applicationData.stateId);
            }, 500);
          }
        });
    },
    updateCompanyInfo($event, name, value) {
      name = name || $event.target.name;

      if (name) {
        const companyInfo = this.applicationData.companyInfo || {};
        companyInfo[name] = value || $event?.target?.value;
        this.updateApplicationData({ companyInfo });
      }
    },
    updateGrossSales(event) {
      this.updateCompanyInfo(event.originalEvent, "grossSales", event.value);
    },
    getTaxOfficeName(val, noCity) {
      const type = val.type.replace(" Müdürlüğü", "");
      const name = `${val.name} ${type}`;
      return name + (noCity ? "" : ", " + val.city);
    },
    getFilterInput(id) {
      return document.querySelector("#" + id + " input.p-multiselect-filter");
    },
    onNaceCodesShow() {
      this.getFilterInput("naceCodes").focus();
    },
    onNaceCodesChange() {
      this.$refs.naceCodes.filterValue = null;
      this.$refs.naceCodes.hide();

      this.updateCompanyInfo(null, "naceCodes", this.naceCodes.sort());
    },
    loadNaceCodes(query, selectedValue) {
      apiClient
        .searchNaceCodes(query, selectedValue)
        .then(response => {
          if (response && Array.isArray(response) && response.length > 0) {
            this.naceCodesList = [...response];
            this.$refs.naceCodes.filterValue =
              (this.$refs.naceCodes.filterValue || "") + "";
          }
        })
        .catch()
        .then(() => {
          this.naceCodesEmptyFilterMessage = "Sonuç bulunamadı";
        });
    },
    onNaceCodesFilter(e) {
      this.naceCodesEmptyFilterMessage = "Yükleniyor...";

      if (this.naceCodesFilterTimer) {
        clearTimeout(this.naceCodesFilterTimer);
        this.naceCodesFilterTimer = null;
      }
      this.naceCodesFilterTimer = setTimeout(() => {
        this.loadNaceCodes(e.value, this.naceCodes);
      }, 500);
    },
    onHqCityChange() {
      this.taxOffice = null;

      if (this.tradeRegistrationOffices.length === 1) {
        this.tradeRegistrationOffice = this.tradeRegistrationOffices[0];
      } else {
        this.tradeRegistrationOffice = null;
      }

      const companyInfo = (this.applicationData || {}).companyInfo || {};
      companyInfo.taxOffice = this.taxOffice;
      companyInfo.tradeRegistrationOffice = this.tradeRegistrationOffice;

      this.updateApplicationData({ hqCity: this.hqCity, companyInfo });
    }
  },
  computed: {
    isSoleCompany() {
      return this.applicationData?.companyInfo?.companyType?.value === "shsfrm";
    },
    hasHqCity() {
      return !!this.hqCity;
    },
    taxOffices() {
      return taxOffices.filter(o => {
        return slugify(o.city).startsWith(this.hqCity);
      });
    },
    tradeRegistrationOffices() {
      return tradeRegistrationOffices.filter(o => {
        return slugify(o).startsWith(this.hqCity);
      });
    },
    prevButtonVisible() {
      return !this.applicationData.fromReview;
    },
    nextButtonText() {
      return this.applicationData.fromReview ? "Kaydet" : "Devam Et";
    },
    stepName() {
      return "company-info";
    },
    validator() {
      return this.$v || {};
    },
    grossSalesLabel() {
      return new Date().getFullYear() - 1 + " Yılı Brüt Satış Tutarı (TL)";
    },
    companyCategories() {
      return companyCategories;
    },
    identityNumber() {
      return this.applicationData.companyInfo.identityNumber;
    },
    companyType() {
      return this.applicationData.companyInfo.companyType;
    },
    taxNumber() {
      return this.applicationData.companyInfo.taxNumber;
    },
    isServiceExporter() {
      return this.applicationData.exportType === "hizmet";
    }
  },
  beforeMount() {
    console.log(slugify("Ağrı"));
    const companyInfo = (this.applicationData || {}).companyInfo || {};

    this.hqCity = this.applicationData.hqCity;

    this.companyName = companyInfo.companyName;
    this.taxOffice = companyInfo.taxOffice;
    this.mersisNumber = companyInfo.mersisNumber;
    this.tradeRegistrationOffice = companyInfo.tradeRegistrationOffice;
    this.tradeRegistrationNumber = companyInfo.tradeRegistrationNumber;
    this.naceCodes = companyInfo.naceCodes;

    if (!this.isSoleCompany) {
      this.tradeRegistrationType = "ticaret-sanayi-odasi";
    } else {
      this.tradeRegistrationType =
        companyInfo.tradeRegistrationType || "ticaret-sanayi-odasi";
    }

    if (this.naceCodes && this.naceCodes.length > 0) {
      this.loadNaceCodes("no-query-$", this.naceCodes);
    }

    this.grossSales = companyInfo.grossSales;
    if (this.grossSales === "") this.grossSales = null;

    if (
      this.isBrokerRegistration &&
      (!companyInfo.companyCategory || companyInfo.companyCategory.length === 0)
    ) {
      this.companyCategory = [this.requiredCategories.broker];

      this.updateCompanyInfo(null, "companyCategory", this.companyCategory);
    } else {
      if (
        !companyInfo.companyCategory ||
        companyInfo.companyCategory.length === 0
      ) {
        this.companyCategory = [this.requiredCategories.exporter];
        this.updateCompanyInfo(null, "companyCategory", this.companyCategory);
      } else {
        this.companyCategory = companyInfo.companyCategory;
      }
    }

    this.markStepAsInCompleted(this.stepName);
  }
};
</script>

<style lang="scss">
.p-multiselect-item {
  .p-dropdown-desc {
    white-space: normal;
    display: block;
  }
}
</style>
